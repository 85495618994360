<template>
	<span>
		<span v-if="category != {}">
	    <span v-if="!category.is_year">{{ category.year_string }}</span>
	    <span class="badge badge-primary badge-pill">{{ category.count }}</span>
	  </span>
	</span>
</template>

<script>
import axios from 'axios'
export default {

  name: 'categoryDetails',
  props: ['categoryId'],
  data () {
    return {
    	category: {}
    }
  },
  methods: {
  	fetchInfo() {
  		axios.get(`/categories/${this.categoryId}/small_info.json`)
  		.then((response) => {
  			this.category = response.data
  		  // TODO
  		}).catch((error) => {
  		  console.error(error);
  		}).finally(() => {
  		  // TODO
  		});

  	}
  },
  mounted() {
  	this.fetchInfo()
  }
}
</script>

<style lang="css" scoped>
</style>