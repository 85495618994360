<template>
	<div id="details" class="bg-white rounded">
		<div id="car-header" class="container-fluid pb-2">
			<div class="row pt-2">
				<div class="col text-center">
					<h1 style="font-weight: 200;" class="mb-0">{{ car.full_name }}</h1>
					<div class="w-100 small text-muted" v-for="lot in car.auction_lots" :key="lot.id">
						<span class="">
							{{lot.auction.auction_house }} &middot; {{lot.auction.name}} &middot; {{lot.auction.date}} &middot; {{lot.show_price}}
						</span>
						&middot; <a :href="`/cars/${car.id}`" target="_blank">view car</a>
						&middot; <a class="border p-2" :href="lot.original_url" target="_blank">view original lot</a>
					</div>
				</div>
			</div>
		</div>
		<div id="car-body" class="container-fluid">
			<div class="row">
				<div class="col pt-2 pb-4" style="overflow: auto; column-count: 3;">
					<div v-for="lot in car.auction_lots" :key="lot.id">
            <h4 style="font-size: 18px" v-if="car.auction_lots.length > 1">{{lot.auction.name}}</h4>
						<div v-html="highlight(lot.html_description)"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	components: {
	},
  props: ['car'],
  data () {
    return {
    }
  },
  computed: {
    keywords() {
      return [
				/([0-9]+[\s,.]?[0-9]{3,10})/,
  			/km/,
  			/kilometers/,
        /carfax/,
        /owners/,
  			/miles/,
  			/odometer/,
  			/transmission/,
  			/gear/,
  			/manual/,
  			/automatic/
      ]
    }
  },
  methods: {
  	highlight(text) {
  		let t = text
  		for (var i = this.keywords.length - 1; i >= 0; i--) {
  			const regex = this.keywords[i]
  			t = t.replace(new RegExp(regex, "gi"), match => {
	        return '<span class="highlightText">' + match + '</span>';
		    });
  		}
  		return t
    }
  }

}
</script>

<style lang="css" scoped>

#car-header{
	/*grid-row-start: 1;
	grid-row-end: 2;
	background: green;*/
}

#car-body{
	font-size: 0.8em;
}
#car-body p{
	margin-bottom: 10px;
}

#details{
	/*display: grid;
	grid-template-rows: 90px 1fr;*/
}
</style>
