/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import Vue from 'vue'
import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
// import App from '../app.vue'

// import ES6 style
import {VueMasonryPlugin} from 'vue-masonry'
Vue.use(VueMasonryPlugin)


import VueWaypoint from 'vue-waypoint'
Vue.use(VueWaypoint)

import CommandCenter from '../components/commandCenter.vue'
import CategorizeCars from '../components/categorizeCars.vue'
import VueProgressiveImage from 'vue-progressive-image'
import Auction from '../components/auction.vue'
import CarPhotos from '../components/carPhotos.vue'
import CategoryDetails from '../components/categoryDetails.vue'

import VueClip from 'vue-clip'
Vue.use(VueClip)

Vue.use(VueProgressiveImage)

Vue.component('commandcenter', CommandCenter)
Vue.component('categorizecars', CategorizeCars)
Vue.component('auction', Auction)
Vue.component('car-photos', CarPhotos)
Vue.component('categorydetails', CategoryDetails)

// const routes = [
//   { path: '/command-center', CommandCenter }
// ]

// const router = new VueRouter({
//   routes
// })

document.addEventListener('DOMContentLoaded', () => {
	// console.log("Loading VUE")
  // const app = new Vue({
  //   render: h => h(App)
  // }).$mount()
  // document.body.appendChild(app.$el)

 const app = new Vue({
    el: '[data-behaviour="vue"]',
    // router,
    mixin: [],
  })
  // console.log(app)
})
