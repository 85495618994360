<template>
  <div>
    <div class="container pt-2">
      <div class="row">

        <div class="col">
          <div class="form-group">
            <select v-on:keyup.enter="doFilter" class="form-control" id="make" v-model="selectedMake" ref="makeselect" :autofocus="!makeId">
              <option v-if="selectedMake == '0'" value="0">Select brand</option>
              <option :value="make.id" v-for="make in makes">{{ make.name }}</option>
            </select>
          </div>
        </div>

        <div class="col-2">
          <button @click="doFilter" class="btn btn-primary btn-block" :disabled="selectedMake == '0'">Filter</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import 'bootstrap/scss/bootstrap.scss'

export default {

  name: 'OptionSelector',
  // components: {VueBootstrapTypeahead},
  props: ['makes', 'makeId', 'selectedStat', 'selectType'],
  data () {
    return {
      selectedMake: "0",
      selectedModel: "0",
      stat: "noTransmission",
      models: [],
      order: 'car_old_to_new'
    }
  },
  created(){
    if (this.makeId){
      this.selectedMake = this.makeId
      // this.stat         = this.selectedStat
    }
  },
  computed: {
    selectModel() {
      return ((this.selectedMake != 0) && (this.selectedMake != "all") && (this.selectedMake != "all_newest_first")) && this.selectType != 'categorize'
    }
  },
  methods: {
    doFilter () {
      this.$refs["makeselect"].blur()
      this.$emit('update', {stat: this.stat, make_id: this.selectedMake})
    }
  }

}
</script>

<style lang="css" scoped>
</style>
