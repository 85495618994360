import { render, staticRenderFns } from "./commandCenter.vue?vue&type=template&id=77bcb019&scoped=true&"
import script from "./commandCenter.vue?vue&type=script&lang=js&"
export * from "./commandCenter.vue?vue&type=script&lang=js&"
import style0 from "./commandCenter.vue?vue&type=style&index=0&id=77bcb019&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77bcb019",
  null
  
)

export default component.exports