<template>
  <figure class="figure" :class="{'removed': removed, 'mainphoto': photo.isMain}" style="position: relative;" @mouseover="showButtons = true" @mouseout="showButtons = false" >
    <img :src="photo.url" class="figure-img img-fluid rounded" :alt="photo.caption">
    <figcaption v-if="message" class="figure-caption text-success">{{message}}</figcaption>
    <figcaption v-if="photo.caption && photo.caption.length" class="figure-caption">{{photo.caption}}</figcaption>
    <div style="position: absolute; top: 10px; width: 100%;" v-show="showButtons && !removed">
      <div class="btn-group">
        <button @click="markMain" class="btn btn-success btn-sm" v-if="!photo.isMain">Mark as main picture</button>
        <button @click="remove" class="btn btn-danger btn-sm">Remove picture</button>
      </div>
    </div>
  </figure>
</template>

<script>
import axios from 'axios'
export default {

  name: 'carPhoto',
  props: ['photo', 'carId', 'addPicture'],
  data () {
    return {
      showButtons: false,
      removed: false,
      message: ''
    }
  },
  methods: {
    markMain() {
      axios.get(`/cars/${this.carId}`, {
        params: {
          main_photo_id: this.photo.id
        },
      }).then((response) => {
        // TODO
        this.photo.isMain = true
        console.log(response)
        this.message = "Picture has been marked as main picture"
        setTimeout(function() {
          this.message = ''
        }.bind(this), 2000)
      }).catch((error) => {
        alert("Something went wrong. The administrators (Eskes Media B.V.) have been notified.")
      });
    },
    remove() {
      this.removed = true
      axios.delete(`/images/${this.photo.id}.json`).then((response) => {
        this.message = "Picture has been removed"
        setTimeout(function() {
          this.message = ''
        }.bind(this), 2000)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
  .removed{
    opacity: 0.1;
    transition: opacity 2s;
  }
  .mainphoto{
    border: 3px solid green;
    border-radius: 7px;
  }
</style>
