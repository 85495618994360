<template>
	<div>
		<div class="container pt-2">
			<div class="row">
        
				<div class="col">
				  <div class="form-group">
				    <select v-on:keyup.enter="doFilter" @change="loadModels" class="form-control" id="make" v-model="selectedMake" ref="makeselect" :autofocus="!makeId">
							<option v-if="selectedMake == '0'" value="0">Select make</option>
							<option value="all_newest_first">All makes, new lots first</option>
							<option value="all">All makes (random, max. 100)</option>
				      <option :value="make.id" v-for="make in makes">{{ make.name }}</option>

				    </select>
				  </div>
				</div>

        <div class="col" v-if="selectModel">
          <div class="form-group">
            <select v-on:keyup.enter="doFilter" class="form-control" id="model" v-model="selectedModel" ref="makeselect">
              <option v-if="selectedModel == '0'" value="0">Select model</option>
              <option value="all">All models</option>
              <optgroup :label="model.category.name" v-for="model in models">
                <option :value="model.category.id">{{ model.category.name }}</option>
                <option :value="child.id" v-for="child in model.children">- {{ child.name }}</option>
              </optgroup>
            </select>
          </div>
        </div>

				<div class="col">
				  <div class="form-group">
				    <select v-on:keyup.enter="doFilter" class="form-control" id="transmission" v-model="stat">
				      <option value="allCars">No filter (all cars)</option>
                      <option value="noTransmission">With unknown transmission</option>
				      <option value="noMileage">With unknown mileage</option>
				      <option value="noVehicleCondition">With unknown Vehicle Condition</option>
				      <option value="noMakeAssigned">Without Make were set</option>
              <!-- <option v-for="check in dataChecks" :value="check[1]">{{ check[0] }}</option> -->
              
				    </select>
				  </div>
				</div>

				<div class="col" v-if="selectedMake != 'all_newest_first'">
				  <div class="form-group">
				    <select v-on:keyup.enter="doFilter" class="form-control" id="age" v-model="order">
				      <option value="sold_last_year">Sold in last year</option>
              <option value="sold_last_two_years">Sold in last 2 years</option>
              <option value="sold_last_five_years">Sold in last 5 years</option>
              <option value="car_old_to_new">Age of cars: old to young</option>
				      <option value="car_new_to_old">Age of cars: young to old</option>
				      <option value="lot_old_to_new">Creation date: old to young</option>
				      <option value="lot_new_to_old">Creation date: young to old</option>

				    </select>
				  </div>
				</div>

				<div class="col-2">
					<button @click="doFilter" class="btn btn-primary btn-block" :disabled="selectedMake == '0'">Filter</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import axios from 'axios'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import 'bootstrap/scss/bootstrap.scss'

export default {

  name: 'OptionSelector',
  components: {VueBootstrapTypeahead},
  props: ['makes', 'makeId', 'selectedStat', 'dataChecks'],
  data () {
    return {
    	selectedMake: "0",
      selectedModel: "0",
    	stat: "allCars",
      models: [],
    	order: 'car_old_to_new'
    }
  },
  created(){
  	if (this.makeId){
  		this.selectedMake = this.makeId
  		this.stat 				= this.selectedStat
  	}
  },
  computed: {
    selectModel() {
      return ((this.selectedMake != 0) && (this.selectedMake != "all") && (this.selectedMake != "all_newest_first"))
    }
  },
  methods: {
    loadModels() {
      if (this.selectModel) {
        this.selectedModel = "all"
        return axios.get(`/categories/${this.selectedMake}/children.json`).
          then(response => {
            this.models = response.data
            // this.car     = response.data.car
            // this.loading = false
          })
      }
    },
  	doFilter () {
  		this.$refs["makeselect"].blur()
  		this.$emit('update', {stat: this.stat, make_id: this.selectedMake, order: this.order, model_id: this.selectedModel})
  	}
  }

}
</script>

<style lang="css" scoped>
</style>
