<template> 
  <div style="" :key="`car-${car.id}`" v-if="car">
    <div class="card-body text-center py-4" v-if="loading">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <div id="lot-details" class="container-fluid" v-if="!loading">
      <div class="row" id="top">
        <div class="col-2 text-left text-white">
          <button class="btn btn-outline-light btn-lg" @click="close">Close (esc)</button>
        </div>
        <div class="col text-center text-white d-flex justify-content-between">
          <h1>{{ car.full_name }}</h1>
          <div style="align-self: center;">
            <a class="badge badge-light mr-1" :href="`/browse/${category.id}`" target="_blank" v-for="category in car.categories">{{category.name}}</a>
          </div>

          <div class="pt-2">
            <button @click="selectLot(prevlot)" v-if="prevlot" class="btn  btn-outline-light"><i class='fa fa-chevron-left'></i> Previous</button>
            <button @click="selectLot(nextlot)" v-if="nextlot" class="btn  btn-outline-light">Next <i class='fa fa-chevron-right'></i> </button>
          </div>
          
        </div>

      </div>
      <div class="row" id="car-main">
        <div class="col col-md-3">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="javascript:void(0)" @click="selectTab('car')" class="nav-link" :class="{active: currentTab == 'car'}">Car</a>
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" @click="selectTab('lots')" class="nav-link" :class="{active: currentTab == 'lots'}">Lot info</a>
            </li>

            <li class="nav-item">
              <a href="javascript:void(0)" @click="selectTab('category')" class="nav-link" :class="{active: currentTab == 'category'}">Category</a>
            </li>
          </ul>
          <div class="border border-dark rounded p-1" style="height: calc(100vh - 205px); overflow: auto; border-top-left-radius: 0px; border-top-right-radius: 0px;">
            <div v-show="currentTab == 'car'">
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td style="font-family: monospace;">{{ car.id }}</td>
                  </tr>
                  <tr>
                    <th>Make</th>
                    <td>{{ car.make }}</td>
                  </tr>
                  <tr>
                    <th>Model</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.model"></td>
                  </tr>
                  <tr>
                    <th>Year</th>
                    <td>
                      <input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.year">
                    </td>
                  </tr>
                  <tr>
                    <th>Chassis Number</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.chassis_no"></td>
                  </tr>
                  <tr>
                    <th>Engine Number</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.engine_no"></td>
                  </tr>
                  <tr>
                    <th>Body Number</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.body_no"></td>
                  </tr>
                  <tr>
                    <th>Exterior Color</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.exterior_color_name"></td>
                  </tr>
                  <tr>
                    <th>Interior Color</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.interior_color_name"></td>
                  </tr>
                  <tr>
                    <th>Gearbox Number</th>
                    <td><input v-on:keyup.enter="submit" type="text" class="form-control form-control-sm" placeholder="" v-model="car.gearbox_no"></td>
                  </tr>
                  <tr>
                    <th>Transmission Type</th>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="transmissionType" id="transmissionType1" value="automatic" v-model="car.transmission_type">
                        <label class="form-check-label" for="transmissionType1">
                          Automatic
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="transmissionType" id="transmissionType2" value="manual" v-model="car.transmission_type">
                        <label class="form-check-label" for="transmissionType2">
                          Manual
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Drive</th>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="lhd" id="lhd1" :value="true" v-model="car.left_hand_drive">
                        <label class="form-check-label" for="lhd1">
                          Left Hand Drive
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="lhd" id="lhd2" :value="false" v-model="car.left_hand_drive">
                        <label class="form-check-label" for="lhd2">
                          Right Hand Drive
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Has Original Engine</th>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="original-engine-" id="original-engine-1" :value="true" v-model="car.has_original_engine">
                        <label class="form-check-label" for="original-engine-1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="original-engine-" id="original-engine-2" :value="false" v-model="car.has_original_engine">
                        <label class="form-check-label" for="original-engine-2">
                          No
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="original-engine-" id="original-engine-3" :value="null" v-model="car.has_original_engine">
                        <label class="form-check-label" for="original-engine-3">
                          Unknown
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Accident Free</th>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="accident" id="accident1" :value="true" v-model="car.accident_free">
                        <label class="form-check-label" for="accident1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="accident" id="accident2" :value="false" v-model="car.accident_free">
                        <label class="form-check-label" for="accident2">
                          No
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-show="currentTab == 'lots'">
              <LotSettings :currencies="currencies" :lot="lot" v-for="lot in car.auction_lots" />
            </div>
            <div v-show="currentTab == 'category'">
              <CarCategorize @update="categorizeUpdate" :car="car" />
            </div>
          </div>
          
          <div v-if="carChanged"  class="pt-2">
            <button @click="saveChanges" class="btn btn-block btn-success">Save Car changes</button>
          </div>
        </div>

        <div class="col-6 bg-light rounded" id="single-lot" style="height: calc(100vh - 112px); overflow: auto;">
          <div v-if="car.auction_lots.length > 1" class="bg-white p-2 rounded mt-2 text-center">
            <em>This car has been sold on more than one auction. If it is not the same car, hit the button below to split the lots.</em>
            <br>
            <a class="btn btn-outline-primary" :href="`/cars/${car.id}/split`">Split lots</a>

          </div>
          <div v-for="lot in car.auction_lots">
            <div class="text-center">
              <h4>{{ lot.auction.auction_house }} | <a :href="`/auctions/${lot.auction.id}`" target="_blank">{{ lot.auction.name }} | {{ lot.auction.date }}</a></h4>
              <h5>{{ lot.show_price }} <small v-if="lot.auction_price < lot.price" class="text-muted">{{ lot.show_auction_price }} excl. commissions</small></h5>
              <a class='btn btn-primary btn-sm text-light' target="_blank" v-if="lot.original_url" :href="lot.original_url">View original lot</a>
            </div>
            <div v-html="highlight(lot.html_description)"></div>
            <p style="font-style: italic; text-align: center;" v-if="lot.extra_data && lot.extra_data.length">Extra Data:</p>
            <div v-html="highlight(lot.extra_data)"></div>
            
          </div>
        </div>

        <div class="col-3" v-if="!loading">
          <CarPhotos :key="`photos-${lot.id}`" :car-id="car.id" style="margin-top: 0px !important;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Keypress from 'vue-keypress'

import CarCategorize from './carCategorize.vue'
import CarPhotos from './carPhotos.vue'
import LotSettings from './lotSettings.vue'
import axios from 'axios'
export default {

  name: 'auctionLotDetails',
  components: {CarPhotos, CarCategorize, LotSettings, Keypress},
  props: ['lot', 'categories', 'currencies', 'nextlot', 'prevlot'],
  data () {
    return {
      loading: true,
      car: null,
      categoryId: null,
      updatedCar: null,
      currentTab: 'car',
      addPicture: false
    }
  },
  computed: {
    keywords() {
      return [
        /([0-9]+[\s,.]?[0-9]{3,10})/,
        /km/,
        /kilometers/,
        /carfax/,
        /autocheck/,
        /owners/,
        /miles/,
        /odometer/,
        /transmission/,
        /gear/,
        /manual/,
        /automatic/
      ]
    },
    carChanged() {
      return !(JSON.stringify(this.updatedCar) === JSON.stringify(this.car))
    }
  },
  methods: {
    selectLot(lot) {
      this.loading = true
      // this.loading = false
      this.car = null
      this.$emit('select', lot.id)
      this.fetchLot()
    },
    highlight(text) {
      let t = text
      for (var i = this.keywords.length - 1; i >= 0; i--) {
        const regex = this.keywords[i]
        t = t.replace(new RegExp(regex, "gi"), match => {
          return '<span class="highlightText">' + match + '</span>';
        });
      }
      return t
    },
    selectTab(tab) {
      this.currentTab = tab
    },
    close() {
      this.$emit('close')
    },
    categorizeUpdate(car){
      console.log('receive update')
      this.car = car
      this.updatedCar = {...car}
      this.$emit('update', car)
    },
    submit() {
      this.saveChanges()
    },
    saveChanges() {
      axios.put(`/cars/${this.car.id}.json`, {
        car: this.car
      }).then((response) => {
        this.car = {...response.data}
        this.updatedCar = {...response.data}
        this.$emit('update', response.data)
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    fetchLot() {
      this.addPicture = false
      this.loading = true
      this.car = null
      axios.get(`/auction_lots/${this.lot.id}/car_info.json`,
      ).then((response) => {
        // TODO
        this.car = {...response.data}
        this.updatedCar = {...response.data}
        this.categoryId = response.data.non_year_category_id
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false
        // TODO
        // prerender the page
        for (var i = this.car.auction_lots.length - 1; i >= 0; i--) {
          var url = this.car.auction_lots[i].original_url
          var hint = document.createElement("link")
          hint.setAttribute("rel", "prerender")
          hint.setAttribute("href", url)
        }
      });
    }
  },
  mounted() {
    console.log('loading lot...')
    this.fetchLot()
  }
}
</script>

<style lang="css" scoped>
  #lot-details {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #fff;
    z-index: 1240;
    height: calc(100vh);
    border: 5px solid black;
    display: grid;
    grid-template-rows: 56px 1fr;
    grid-template-columns: 1fr;
  }

  #top {
    grid-row-start: 1;
    grid-row-end: 2;
    background: #000;
  }

  #car-main {
    overflow: auto;
    grid-row-start: 3;
    grid-row-end: 3;
    background: #fff;
    padding-top: 10px;
  }
  .nav-tabs .active{
    border-color: #343a40 #343a40 #fff !important;
  }
</style>