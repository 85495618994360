<template>
	<div id="command-center" class="bg-light">
<!-- 
		<Keypress :key-code="39" event="keyup" @pressed="selectNext" />
		<Keypress :key-code="37" event="keyup" @pressed="selectPrev" />

		<Keypress :key-code="74" event="keyup" @pressed="selectNext" />
		<Keypress :key-code="75" event="keyup" @pressed="selectPrev" />

		<Keypress :key-code="40" event="keyup" @pressed="selectNext" />
		<Keypress :key-code="38" event="keyup" @pressed="selectPrev" />

    <Keypress :key-code="81" event="keyup" @pressed="selectedCar.has_original_engine = true" v-if="selectedCar" />
    <Keypress :key-code="87" event="keyup" @pressed="selectedCar.has_original_engine = false" v-if="selectedCar" />
    <Keypress :key-code="69" event="keyup" @pressed="selectedCar.has_original_engine = null" v-if="selectedCar" />
    <Keypress :key-code="82" event="keyup" @pressed="selectedCar.left_hand_drive = false" v-if="selectedCar" />
    <Keypress :key-code="76" event="keyup" @pressed="selectedCar.left_hand_drive = true" v-if="selectedCar" />
    <Keypress :key-code="78" event="keyup" @pressed="selectedCar.accident_free = true" v-if="selectedCar" />
    <Keypress :key-code="89" event="keyup" @pressed="selectedCar.accident_free = false" v-if="selectedCar" />

    <Keypress :key-code="67" event="keyup" @pressed="selectedCar.celebrity = true" v-if="selectedCar" />
    <Keypress :key-code="88" event="keyup" @pressed="selectedCar.celebrity = false" v-if="selectedCar" />





		<Keypress :key-code="77" event="keydown" @pressed="markCar('manual')" />
		<Keypress :key-code="85" event="keydown" @pressed="markCar('unknown')" />
		<Keypress :key-code="65" event="keydown" @pressed="markCar('automatic')" />

		<Keypress :key-code="13" event="keyup" @pressed="saveCarAndRemoveFromList()" />
 -->
		<OptionSelector @update="updateFilter" :makeId="makeId" :data-checks="dataChecks" :selectedStat="stat" :makes="makes" class="bg-light" id="option-selector"></OptionSelector>

		<div id="photos" class="bg-light"v-if="selectedCar">

      <div class="list-group" v-if="showSaved">
        <button @click="loadCar(car)" v-for="car in workedOff" class="text-left list-group-item small p-1 list-group-item-success">{{ car.full_name }}</button>
      </div>


      <div class="list-group" v-if="showList">
        <button @click="loadCar(car)" v-for="car in cars" class="text-left list-group-item small p-1" v-bind:class="{'active' : selectedCar != null && car.id == selectedCar.id }">{{car.year }} {{ car.full_name }}</button>
      </div>

      <div v-if="!showSaved && !showList">
  			<div v-if="selectedCar.images && !selectedCar.images.length" class="text-center bg-white rounded p-2">
  				😞 This car has no images
  			</div>
  			<progressive-img
  			  :src="image.url"
  			  placeholder="/img/car-placeholder.jpg"
  			  :blur="30"
  			  v-for="image in selectedCar.images"
  			/>
      </div>
		</div>
		<div id="main-screen">
      <div v-if="cars.length == 0 && !loading" class="text-center pt-5">
        No results
      </div>
      <div v-if="!makeId">
        <div class="row mx-0">
          <div class="col-10 mx-auto text-center bg-white shadow rounded mt-4 p-4">
            Choose a make and then look at all the cars of that make to fill in missing data.
            <br>Shortcuts:
            <table class='table table-borderless mt-3 text-left'>
              <tr>
                <th>Next / Previous</th>
                <td class="text-center"><kbd>J</kbd> and <kbd>K</kbd><br><span class="text-muted">(or arrows)</span></td>
              </tr>
              <tr>
                <th>Focus cursor on the first input field</th>
                <td class="text-center"><kbd>F</kbd></td>
              </tr>
              <tr>
                <th>Remove cursor from current input field</th>
                <td class="text-center"><kbd>esc</kbd></td>
              </tr>
              <tr>
                <th>Mark as manual transmission</th>
                <td class="text-center"><kbd>M</kbd></td>
              </tr>
              <tr>
                <th>Mark as automatic transmission</th>
                <td class="text-center"><kbd>A</kbd></td>
              </tr>
              <tr>
                <th>Mark as transmission unknown</th>
                <td class="text-center"><kbd>U</kbd></td>
              </tr>
              <tr>
                <th>Original engine Yes / No</th>
                <td class="text-center"><kbd>E</kbd> and <kbd>R</kbd></td>
              </tr>
              <tr>
                <th>Submit and go to next one</th>
                <td class="text-center"><kbd>Enter</kbd></td>
              </tr>
            </table>
          </div>
        </div>
      </div>



			<CarDetails :car="selectedCar" v-if="selectedCar"></CarDetails>

      <div class="row" v-if="false && development && selectedCar">
        <div class="colmt-4">
          <pre>
            {{ selectedCar }}
          </pre>
        </div>
      </div>


			<div class="row" v-if="workedOff.length && !cars.length">
				<div class="col text-center py-4 mt-4" style="font-size: 36px; font-weight: 100;">
					ready 😎
				</div>
			</div>

			<div class="row" v-if="loading">
				<div class="col text-center py-4">
					<i class="fas fa-spinner fa-spin fa-3x"></i>
				</div>
			</div>


		</div>
		<div id="stats" class="px-1 pt-2" v-if="true">
      <div class="btn-group btn-block">
        <button v-if="cars.length" class="btn btn-outline-primary" @click="showList = !showList"><i class="fas fa-list"></i> List ({{ cars.length }})</button>
  		  <button type="button" class="btn btn-outline-success" v-if="workedOff.length" @click="showSaved = !showSaved"><i class="fas fa-check"></i> {{workedOff.length}} submitted</button>
      </div>
		</div>

		<div id="commands" class="bg-light p-2" v-if="selectedCar">


      <div class="d-flex justify-content-around">
				<div>
					<button @click="selectPrev" class="btn btn-primary" :disabled="currentIndex == 0 ">
						<i class="fas fa-arrow-left"></i>
					</button>
				</div>
        <div class="text-muted small pt-2">
          {{ currentIndex + 1}} / {{ cars.length }}
        </div>
        <div>
          <button @click="selectNext" class="btn btn-primary" :disabled="!canSelectNext">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>


        <div class="my-2">
          <div v-for="lot in selectedCar.auction_lots">
            <MileageInput :stat="stat" :car="selectedCar" :lot="lot"></MileageInput>
          </div>
        </div>

        <div class="row m-0">
          <div class="col">
            <label class="form-control-label text-center small w-100 mb-0 ">Chassis/VIN number</label>
            <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" v-model="selectedCar.chassis_no">
            <button v-if="stat == 'wrong_chassis_no'" @click="skipWrongChassis" class="btn btn-warning btn-block btn-sm mt-2">Skip in the future</button>
          </div>
        </div>

        <div class="row m-0">
          <div class="col">
            <label class="form-control-label text-center small w-100 mb-0 ">Exterior Color</label>
            <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" v-model="selectedCar.exterior_color_name">
          </div>
          <div class="col">
            <label class="form-control-label text-center small w-100 mb-0 ">Interior Color</label>
            <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" v-model="selectedCar.interior_color_name">
          </div>
        </div>


        <label class="form-control-label text-center small w-100 mb-0 ">Transmission</label>
				<div>
					<div class="btn-group btn-block mb-2">
						<button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.transmission_type == 'manual'}" @click="selectedCar.transmission_type = 'manual'" >
							Man. <kbd>M</kbd>
						</button>
						<button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.transmission_type == 'automatic'}" @click="selectedCar.transmission_type = 'automatic'">
							Auto. <kbd>A</kbd>
						</button>
						<button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.transmission_type == 'unknown'}" @click="selectedCar.transmission_type = 'unknown'">
							Unkn. <kbd>U</kbd>
						</button>
					</div>
        </div>


        <label class="form-control-label text-center small w-100 mb-0 ">Has original engine</label>
        <div>
          <div class="btn-group btn-block mb-2">
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.has_original_engine === true}" @click="selectedCar.has_original_engine = true">
              Yes <kbd>Q</kbd>
            </button>
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.has_original_engine === false}" @click="selectedCar.has_original_engine = false">
              No <kbd>W</kbd>
            </button>
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.has_original_engine == null}" @click="selectedCar.has_original_engine = null">
              Unknown <kbd>E</kbd>
            </button>
          </div>
        </div>
        <label class="form-control-label text-center small w-100 mb-0 ">Position steering wheel</label>
        <div>
          <div class="btn-group btn-block mb-2">
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.left_hand_drive == true}" @click="selectedCar.left_hand_drive = true">
              LHD <kbd>L</kbd>
            </button>
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.left_hand_drive == false}" @click="selectedCar.left_hand_drive = false">
              RHD <kbd>R</kbd>
            </button>
          </div>
        </div>
        <label class="form-control-label text-center small w-100 mb-0 ">Accident</label>
        <div>
          <div class="btn-group btn-block mb-2">
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.accident_free == true}" @click="selectedCar.accident_free = true">
              No <kbd>N</kbd>
            </button>
            <button class="btn btn-primary btn-sm" v-bind:class="{'active btn-active' : selectedCar.accident_free == false}" @click="selectedCar.accident_free = false">
              Yes <kbd>Y</kbd>
            </button>
          </div>
        </div>

        <div id="save" class="bg-light">
          <button class="btn btn-success btn-block" @click="saveCarAndRemoveFromList()" :disabled="!canSave()">
            <i class="fas fa-save"></i> Submit
          </button>
        </div>

		</div>


	</div>
</template>

<script>

import Keypress from 'vue-keypress'
import OptionSelector from './optionSelector.vue'
import CarDetails from './carDetails.vue'
import MileageInput from './mileageInput.vue'

import axios from 'axios'

export default {

  name: 'test',
  components: {MileageInput, OptionSelector, CarDetails, Keypress},
  props: ['development', 'makes', 'dataChecks'],
  data () {
    return {
    	loading: false,
    	cars: [],
    	workedOff: [],
    	showSaved: false,
      showList: false,
    	total: 0,
      order: null,
    	stat: null,
    	makeId: null,
    	currentIndex: 0,
    	selectedCar: null,
    	markedTManual: false,
    	markedTAutomatic: false,
    	markedTUnknown: false,
      expandedGear: true
    }
  },
  created(){
		axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
		this.parseFromUrl()
    if (this.development){
      this.expandedGear = true
    }
		// window.addEventListener('popstate', (event) => {

		//   this.parseFromUrl()
		// });
  },
  computed: {
    canSelectNext(){
      if (this.currentIndex < (this.cars.length - 1)){
        return true
      } else {
        return false
      }
    },
    commandGridRowStyle(){
      let h = 58
      if (this.selectedCar && this.selectedCar.auction_lots && this.selectedCar.auction_lots.length > 1){
        h = this.selectedCar.auction_lots.length * 48
      } else {
        // const h = 58
      }
      return `grid-template-rows: 56px 1fr ${h}px;`
    }

  },
  methods: {
  	parseFromUrl(){
  		// console.log(this.$router)
  		// ["", "command-center", "noTransmission", "329812", "223663"]
  		const attrs = window.location.pathname.split("/")
  		if (attrs.length > 1){
  			this.stat 	= attrs[2]
  			this.makeId = attrs[3]
        if (this.stat && this.makeId){
  				this.updateFilter({order: this.order, stat: this.stat, make_id: this.makeId, carId: attrs[4]})
        }
  		}

  	},
    skipWrongChassis(){
      var car = this.selectedCar
      return axios.put(`/cars/${this.selectedCar.id}/skip-in-future.json`, {car, stat: this.stat}).
      then(response => {
        // console.log(response.data)
        // this.workedOff.push(car)
        alert("OK, this car is excluded in the future")
      }).catch(error => {
        alert("Something went wrong on the server. A message has been sent to the administrators.")
      });
    },
  	canSave(){
  		// return (this.markedTAutomatic === true || this.markedTUnknown === true || this.markedTManual === true )
      return true
  	},
  	markCar(type){
  		if (this.selectedCar){
        this.selectedCar.transmission_type = type
	  		// console.log(`marking car as ${type}`)
	  		// if (type == 'manual') {
	  		// 	this.markedTAutomatic = false
		  	// 	this.markedTUnknown 	= false
		  	// 	this.markedTManual 		= true
	  		// } else if (type == 'unknown'){
					// this.markedTAutomatic = false
		  	// 	this.markedTUnknown 	= true
		  	// 	this.markedTManual 		= false
	  		// } else if (type == 'automatic') {
					// this.markedTAutomatic = true
		  	// 	this.markedTUnknown 	= false
		  	// 	this.markedTManual 		= false
	  		// }
  		}
  	},
  	saveCar(){
			var car = this.selectedCar
			return axios.put(`/cars/${this.selectedCar.id}/command-center.json`, {car, stat: this.stat}).
      then(response => {
        // console.log(response.data)
        this.workedOff.push(car)
      }).catch(error => {
        alert("Something went wrong on the server. Submittion failed. A message has been sent to the administrators.")
      });

  	},
  	saveCarAndRemoveFromList() {
  		if (this.selectedCar && this.canSave()){
  			this.saveCar()
	  		this.markedTAutomatic = false
	  		this.markedTUnknown 	= false
	  		this.markedTManual 		= false
	  		this.cars.splice(this.currentIndex, 1)
	  		if (this.cars[this.currentIndex]){
		  		this.loadCar(this.cars[this.currentIndex])
	  		} else if (this.cars.length == 1 && this.cars[0].id == this.selectedCar.id){
	  			this.selectedCar = null
	  			history.pushState({}, "Done", `/command-center`)
		  	} else if (this.cars.length) {
	  			this.loadCar(this.cars[this.cars.length - 1])
	  		}	else {
	  			this.selectedCar = null
	  			history.pushState({}, "Done", `/command-center`)
	  		}
  		}


  		// arr.splice(2,2);
  	},
  	loadCar(car){


  		// this.loading = true
      this.showSaved = false
      this.showList = false
  		this.selectedCar = car
			return axios.get(`/cars/${car.id}.json`).
	      then(response => {
	      	this.loading = false
	      	// only load if reponse.data.id == selectedCar.id
	      	if (this.selectedCar.id == response.data.id) {
	      		history.pushState({selectedCar: this.selectedCar, currentIndex: this.currentIndex}, car.full_name, `/command-center/${this.stat}/${this.makeId}/${response.data.id}`)
		        this.selectedCar = response.data
	      	}
	      })
  	},
  	selectPrev(){
  		if (this.currentIndex > 0){
	  		this.loading = true
	  		this.selectedCar = null
	  		this.currentIndex --
	  		this.loadCar(this.cars[this.currentIndex])
  		}
  	},
  	selectNext(){
  		if (this.canSelectNext){
	  		this.loading = true
	  		this.selectedCar = null
	  		this.currentIndex ++
	  		this.loadCar(this.cars[this.currentIndex])
	  	}
  	},
  	updateFilter(obj){
  		this.loading 			= true
  		this.total				= 0
  		this.cars 				= []
  		this.workedOff		= []
  		this.showSaved 		= false
  		this.selectedCar 	= null
  		this.makeId 			= obj.make_id
  		this.stat 				= obj.stat
  		return axios.get(`/auction_lots/filter-results/${obj.stat}/${obj.order}/${obj.make_id}.json?car_id=${obj.carId}&model_id=${obj.model_id}`).
      then(response => {
        this.cars 				= response.data.cars
        this.total 				= response.data.total
        this.loading 			= false
        this.currentIndex = 0
        if (this.cars.length){
        	if (obj.carId){
        		for (var i = this.cars.length - 1; i >= 0; i--) {
        			if (this.cars[i].id == obj.carId){
		        		this.currentIndex = i
		        		this.loadCar(this.cars[i])
        			}
        		}
        	} else {
	        	this.loadCar(this.cars[this.currentIndex])
        	}
        }
      })
  	}
  }
}
</script>

<style lang="css" scoped>
	#option-selector{
		grid-row-start: 1;
		grid-column-start: 2;
		grid-column-end: 3;

	}
	#photos {
		grid-row-start: 2;
		grid-row-end: 4;
		grid-column-start: 1;
		overflow: auto;
		padding: 10px;
	}
  #save{
    width: 300px;
    padding-right: 8px;
    position: absolute;
    padding-top: 5px;
    bottom: 0px;
    padding-bottom: 5px;
    padding-left: 8px;
    right: 0px;
    z-index: 9999;
    border-top: 1px solid #ddd;
  }
	#commands {
		/*grid-row-start: 3;
		grid-column-start: 1;
		grid-column-end: 4;*/
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 3;
		overflow: auto;
		border-left: 1px solid #eee;
		background-color: #f7f7f7;
		-webkit-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.67);
		-moz-box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.67);
		box-shadow: 0px 0px 15px -1px rgba(0,0,0,0.67);
		z-index: 888;
    padding-bottom: 60px !important;


	}
	#stats {
		grid-row-start: 1;
		grid-row-end: 1;
		grid-column-start: 1;
    grid-column-end: 2;
		overflow: auto;
	}
	#main-screen{
		grid-row-start: 2;
		grid-row-end: 4;
		grid-column-start: 2;
		grid-column-end: 3;
		overflow: auto;
	}
	#command-center{
		background: #fefefe;
		height: calc(100vh);
		display: grid;
    grid-template-rows: 56px 1fr 56px;
    grid-template-columns: 300px 1fr 300px;
	}
  .btn-active{
    color: #fff;
    background-color: #0062cc;
    border: 2px solid #000000 !important;
  }

</style>
