<template>
  <div style="height: calc(100vh - 100px); overflow-y: auto;" class="bg-light rounded mt-4 p-4">
    <div class="text-center">
      <div v-if="addPicture">
        <vue-clip :options="options" :on-queue-complete="queueCompleted" :on-complete="complete">
          <template slot="clip-uploader-action">
            <div>
              <div class="dz-message bg-white rounded py-4 text-center" style="border: 3px dashed #ddd;"><h6> Click or Drag and Drop files here to upload</h6></div>
            </div>
          </template>

          <template slot="clip-uploader-body" scope="props">
            <div v-for="file in props.files">
              <img v-bind:src="file.dataUrl" />
              {{ file.name }} {{ file.status }}
            </div>
          </template>

        </vue-clip>
      </div>
      <div v-if="!addPicture">
        <h2>Pictures</h2>
        <div v-if="!loading && !photos.length">
          No photos ☹️
        </div>
        <div v-if="loading">
          <i class='fa fa-spinner fa-spin'></i> <em>Loading pictures...</em>
        </div>
        <car-photo :photo="photo" :car-id="carId" v-for="photo in photos" />
        <div v-if="!loading">
          <button @click="addPicture = true" class="btn btn-outline-primary btn-sm">Upload photo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import carPhoto from './carPhoto'
export default {

  name: 'carPhotos',
  props: ['carId'],
  components: {'car-photo': carPhoto},
  data () {
    return {
      loading: true,
      addPicture: false,
      photos: [],
      options: {
        url: `/cars/${this.carId}/upload-photo.json`,
        paramName: 'photo',
        acceptedFiles: {
          extensions: ['image/*'],
          message: 'You are uploading an invalid file'
        }
      }
    }
  },
  methods: {
    complete (file, status, xhr) {
      console.log('complete')
      // console.log(xhr.response)
      this.photos = JSON.parse(xhr.response)
    },
    queueCompleted () {
      this.addPicture = false
    }
  },
  created() {
    axios.get(`/cars/${this.carId}/photos.json`).then((response) => {
      console.log(response)
      this.loading = false
      this.photos = response.data
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      // TODO
    });
  }
}
</script>

<style lang="css" scoped>
</style>
