<template>
  <component v-waypoint="{ active: true, callback: onWaypoint }" :is="viewModeComponent('tr', 'div')" v-bind:class="{'col-12 col-md-4 col-lg-3 col-xl-3' : mode == 'cards'}" v-show="!hidden">
    <div class="card mb-3" v-if="mode == 'cards'">
      <div class="card-body text-center py-4" v-if="loading">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <img @click="toggleDetails" :src="car.image" class="card-img-top" v-if="!loading && car.image">
      <img @click="toggleDetails" src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" class="card-img-top" v-if="!loading && !car.image" style="max-height: 222px;">
      <ul class="list-group list-group-flush" v-if="!loading">
        <li class="list-group-item">
          <a class="text-dark" :href="`/cars/${car.id}`">{{ car.model }}</a>
        </li>
        <li class="list-group-item"><span class="">{{car.year}}</span></li>
        <li class="list-group-item">
          <a v-for="cat in car.categories" :href="`/browse/${cat.id}`" target="_blank" class="badge badge-primary">{{ cat.name }}</a>
          <button @click="acceptSuggestion" v-if="car.categories && car.categories.length < 2 && car.category_suggestions.length" class="badge badge-warning" title="Accept suggestion">
            {{ car.category_suggestions[0].name }} ({{ car.category_suggestions[0].score }}%)
          </button>
        </li>
        <li class="list-group-item">{{ show_price }}</li>
      </ul>
    </div>

    <td colspan="9" v-if="loading && mode == 'list' " class="text-center">
       <content-loader :height="10">
            <rect x="3" y="3" rx="3" ry="3" width="34" height="5" /> 
    <rect x="78" y="3" rx="3" ry="3" width="34" height="5" /> 
    <rect x="151" y="3" rx="3" ry="3" width="103" height="5" /> 
    <rect x="264" y="3" rx="3" ry="3" width="162" height="5" /> 
    <rect x="447" y="3" rx="3" ry="3" width="162" height="5" />
       </content-loader>
    </td>
    <td v-if="!loading && mode == 'list'">
      <span>
        <input data-type="car" type="checkbox" class="table-checkbox" name="selected-cars" :value="car.id" :checked="selectedCar == car.id">
      </span>
    </td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list' && !small">{{ lot.lot_number }}</td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list' && !small">{{ carToUse().linked_make_name }}</td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list'">{{ carToUse().model }}</td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list'">{{ carToUse().year }}</td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list'">{{ carToUse().chassis_no }}</td>
    <td v-if="!loading && mode == 'list' && !small">
      <a v-for="cat in carToUse().categories" :href="`/browse/${cat.id}`" target="_blank" class="badge badge-primary">
        {{ cat.name }}
      </a>

      <button @click="acceptSuggestion" v-if="car.categories && car.categories.length < 2 && car.category_suggestions.length" class="badge badge-warning" title="Accept suggestion">
        {{ car.category_suggestions[0].name }} ({{ car.category_suggestions[0].score }}%)
      </button>

    </td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list' && !small">
      {{ show_price }}
      <span v-if="btnBg">
        <i class="far fa-check-circle text-success"></i>
      </span>
    </td>
    <td @click="toggleDetails" v-if="!loading && mode == 'list' && !small">
      {{ vehicle_condition }}
    </td>
    <td v-if="!loading && mode == 'list' && !small">
      <div class="btn-group">
        <button @click="hideDetails" class="btn btn-outline-primary btn-sm" target="_blank" v-if="(currentLot && currentLot.id == lot.id)">Close</button>
        <button @click="showDetails" class="btn btn-outline-primary btn-sm" target="_blank" v-if="!(currentLot && currentLot.id == lot.id)">Details</button>
        <a v-if="false" :href="`/cars/${car.id}`" class="btn btn-outline-primary btn-sm" target="_blank">View Car</a>
        <a v-if="false" :href="`/cars/${car.id}/auction_lots/${lot.id}`" class="btn btn-outline-primary btn-sm" target="_blank">View lot</a>
        <button @click="deleteLot" class="btn btn-sm btn-outline-danger">Delete Lot</button>
        <button @click="hideLot" class="btn btn-sm btn-outline-primary">Hide Lot</button>
      </div>
    </td>
  </component>
</template>

<script>
import axios from 'axios'
import { ContentLoader } from 'vue-content-loader'

export default {

  name: 'auctionLot',
  props: ['auction_id', 'lot', 'mode', 'selectedCar', 'selectedCategory', 'currentLot', 'small', 'lotIndex'],
  components: {ContentLoader},
  data () {
    return {
      loading: true,
      car: null,
      isInView: false,
      show_price: null,
      btnBg: null,
      hidden: false
    }
  },
  // computed: {
    
  // },
  methods: {
    onWaypoint ({ going, direction }) {
      if (!this.isInView) {
        // going: in, out
        // direction: top, right, bottom, left
        if (going === this.$waypointMap.GOING_IN) {
          // console.log('waypoint going in!')
          this.isInView = true
          this.initLoadingCycle()
        }

        if (direction === this.$waypointMap.DIRECTION_TOP) {
          console.log('waypoint going top!')
        }
      }
    },
    carToUse() {
      if (this.lot.updated_car) {
        return this.lot.updated_car
      } else {
        return this.car
      }
    },
    toggleDetails() {
      this.$emit('toggleDetails', this.lot.id)
    },
    hideDetails() {
      this.$emit('hideDetails')
    },
    showDetails() {
      this.$emit('showDetails', this.lot.id)
    },
    hideLot(){
      if (confirm("Are you sure?")) {
        // console.log('hiding lot...')
        this.loading = true
        return axios.delete(`/auctions/${this.auction_id}/auction_lots/${this.lot.id}.json?disable=true`).
        then(response => {
          this.hidden   = true
          this.loading  = false
        })

      }

      // <%= link_to 'Hide', [@auction, auction_lot, disable: true], method: :delete, remote: true, data: {disable_with: 'even geduld...', confirm: "Are you sure?"},  class: 'btn btn-sm btn-outline-primary' %>
    },
    deleteLot(){
      if (confirm("Are you sure?")) {
        this.loading = true
        return axios.delete(`/auctions/${this.auction_id}/auction_lots/${this.lot.id}.json`).
          then(response => {
            this.hidden   = true
            this.loading  = false
          })
      }
      // <%= link_to 'Remove', [@auction, auction_lot], method: :delete, remote: true, data: {confirm: "Are you sure?", disable_with: 'even geduld...'}, class: 'btn btn-sm btn-outline-danger' %>
    },
    viewModeComponent(list, card){
      if (this.mode == 'list'){
        return list
      } else {
        return card
      }
    },
    acceptSuggestion(){
      this.loading = true
      // /cars/<%= auction_lot.car.id %>/approval/accept_suggestion?category_id=<%= auction_lot.car.car_suggestions.first.category_id %>&redirect_url=<%= auction_path(@auction) %>
      return axios.get(`/cars/${this.car.id}/approval/accept_suggestion.json?category_id=${this.car.category_suggestions[0].category_id}`).
        then(response => {
          this.car     = response.data.car
          this.loading = false
        })
    },
    fetchCar(){
      this.initBackgroundLoading()
      return axios.get(`/auction_lots/${this.lot.id}.json`).
      then(response => {
        this.isInView = true
        if (response.data.car) {
          this.car        = response.data.car
          this.show_price = response.data.show_price
          this.btnBg = response.data.has_pricing
          this.vehicle_condition = response.data.vehicle_condition
          this.loading    = false
        } else {
          this.hidden     = true
        }
      })
    },
    initBackgroundLoading() {
      if (this.car && this.loading){
        setTimeout(function() {
          this.fetchCar()
        }.bind(this), 2000)
      }
    },
    initLoadingCycle() {
    if (this.lot.car) {
      this.car = this.lot.car
      this.show_price = this.lot.show_price
      this.btnBg = this.lot.has_pricing
      this.vehicle_condition = this.lot.vehicle_condition
      if (this.car.pending_background_job) {
        this.loading = true
      } else {
        this.loading = false
      }
    } else {
      this.fetchCar()
    }
    this.initBackgroundLoading()
    }
  },
  mounted(){
    // 
    setTimeout(function() {
      if (this.loading) {
        this.initBackgroundLoading()
      }
    }.bind(this), 5000)
  }
}
</script>

<style lang="css" scoped>
</style>
