<template>

	<div>
    <Keypress :key-code="27" event="keyup" @pressed="hideDetails" />
		<div class="row mb-3" v-if="!small">
			<div class="col text-center">
				<div class="btn-group">
					<button @click="viewMode = 'list'" class="btn btn-sm btn-outline-primary" v-bind:class="{'active' : viewMode == 'list'}"><i class="fas fa-list"></i> List</button>
					<button @click="viewMode = 'cards'" class="btn btn-sm btn-outline-primary" v-bind:class="{'active' : viewMode == 'cards'}">Cards <i class="fas fa-car"></i></button>
				</div>
			</div>
		</div>

    <div class="row mb-4">
      <div class="col col-md-6 mx-auto">
        <input class="form-control" v-model="keyword" placeholder="Search by make, chassis number, model, year">
      </div>
    </div>

		<div class="row">
			<div class="col" id="lots-list">
				<component :is="viewModeComponent('table', 'div')" v-bind:class="{'table table-hover table-striped' : viewMode == 'list'}">
	        <thead v-if="viewMode == 'list'">
	          <tr>
	            <th></th>
	            <th v-if="!small">Lot #</th>
	            <th v-if="!small">Make</th>
	            <th>Model/Type</th>
	            <th>Year</th>
              <th>
                Chassis
                <button class="btn btn-link" @click="sort('chassis_no')"><i class="far fa-play-circle"></i></button>
                <button class="btn btn-link" @click="sort('')"><i class="far fa-stop-circle"></i></button>
              </th>
	            <th v-if="!small"></th>
	            <th v-if="!small">Price</th>
	            <th v-if="!small">Condition</th>
	            <th v-if="!small"></th>
	          </tr>
	        </thead>
        <component :is="viewModeComponent('tbody', 'div')" v-bind:class="{'row': viewMode == 'cards'}">
        	<auctionLot v-show="lotIsVisible(lot)" :lot-index="index" :small="small" :current-lot="currentLot" @toggleDetails="toggleDetails" @hideDetails="hideDetails" @showDetails="showDetails" v-if="lot.car_id" v-for="(lot, index) in filteredLots" :selected-car="selectedCar" :selected-category="selectedCategory" :category-id="categoryId" :auction_id="auctionId" :mode="viewMode" :lot="lot" :key="`lot-${lot.id}`"></auctionLot>

        </component>
      </component>
			</div>
		</div>

    <div class="row" v-if="currentLot">
      <div class="col">
        <auctionLotDetails :key="`current-lot-${currentLot.id}`" @select="changeCurrent" @update="updateCurrent" @close="hideDetails" :categories="categories" :nextlot="nextLot" :prevlot="prevLot" :lot="currentLot" :currencies="currencies" />
      </div>
    </div>

	</div>
</template>

<script>
import axios from 'axios'
import Keypress from 'vue-keypress'
import AuctionLot from './auctionLot.vue'
import AuctionLotDetails from './auctionLotDetails.vue'
// import AuctionLotCard from './auctionLotCard.vue'
export default {
  name: 'auction',
  props: ['auction', 'selectedCar', 'selectedCategory', 'category', 'small', 'deleteWhenCategory'],
  components: {AuctionLot, AuctionLotDetails, Keypress},
  data () {
    return {
    	viewMode: 'list',
      currentLot: null,
      categories: null,
      lots: [],
      keyword: '',
      sortable: '',
      currencies: null
    }
  },
  computed: {
    filteredLots() {
      var lotList = this.lots
      var sortKey = this.sortable

      if (sortKey !== '') {
        var sortedLotList = lotList.slice()
        sortedLotList.sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]

          return (a === b ? 0 : a > b ? 1 : -1) * (false ? 1 : -1)
        });
      } else {
        var sortedLotList = lotList
      }

      return sortedLotList.filter((lot) => {
        if (lot.search) {
          return lot.search.toLowerCase().includes(this.keyword.toLowerCase())  
        } else {
          return true
        }
        
      })
    },
    categoryId() {
      if (this.category) {
        return this.category.id
      }
    },
    auctionId() {
      if (this.auction) {
        return this.auction.id
      }
    },
    nextLot() {
      for (var i = 0; i < this.lots.length; i++) {
        if (this.lots[i].id == this.currentLot.id) {
          return this.lots[i + 1]
        }
      }
      return null
    },
    prevLot() {
      for (var i = 0; i < this.lots.length; i++) {
        if (this.lots[i].id == this.currentLot.id) {
          if (i == 0) {
            return null
          } else {
            return this.lots[i - 1]
          }
        }
      }
      return null
    }
  },
  created() {
    // this.fetchCategories()
    this.fetchCurrencies()
    this.initLots()
  },
  mounted() {
    $('#lots-list tbody').checkboxes('range', true);
  },
  methods: {
    fetchCurrencies() {
      axios.get('/currencies.json').then((response) => {
        this.currencies = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    initLots() {
      if (this.auction) {
        this.lots = this.auction.lots
      } else if (this.category) {
        this.lots = this.category.lots
      }
    },
    updateCurrent(car) {
      console.log("replacing car with")
      console.log(car)
      let lotIndex = null

      for (var i = 0; i < this.lots.length; i++) {
        if (this.lots[i].car_id === car.id) {
          lotIndex = i
        }
      }
      // console.log(lotIndex)
      this.lots[lotIndex].updated_car = car

      // this.lots[this.lots.map((x, i) => [i, x]).filter(x => x[1] == old_value)[0][0]] = new_value
    },
    fetchCategories() {
      axios.get('/categories-tree.json'
      ).then((response) => {
        this.categories = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    toggleDetails(lotId) {
      if (this.currentLot == null) {
        this.showDetails(lotId)
      } else {
        this.hideDetails()
      }
    },
    hideDetails() {
      this.currentLot = null
    },
    lotIsVisible(lot) {
      if (this.currentLot && this.currentLot.id == lot.id) {
        // updated_car.categories
        return true
      } else if (this.currentLot == null) {
        if (this.deleteWhenCategory && lot.updated_car && lot.updated_car.categories && lot.updated_car.categories.length > 1) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    changeCurrent(lotId) {
      console.log(`selecting lot ${lotId}`)
      this.currentLot = null
      this.currentLot = {}
      this.currentLot.id = lotId
    },
    showDetails(lotId){
      console.log(lotId)
      this.currentLot = {}
      this.currentLot.id = lotId
    },
    sort(type) {
      this.sortable = type;
    },
  	viewModeComponent(list, card){
  		if (this.viewMode == 'list'){
  			return list
  		} else {
  			return card
  		}
  	}
  }
}
</script>

<style lang="css" scoped>
</style>
