<template>
  <div class="p-2">
    <div v-if="theLot" class="border rounded p-2">
      <div class="text-center small">
        <strong>{{ lot.auction.auction_house }} | {{ lot.auction.date }} | #{{lot.id}}</strong>
      </div>
      <div class="row m-2" v-if="theLot && !editText">
        <div class="col">
          <div class="input-group input-group-sm mb-2">  
            <div class="input-group-prepend">
              <span class="input-group-text small px-1">LOT</span>
            </div>
            <input type="text" class="form-control form-control-sm" placeholder="" v-model="theLot.lot_number">
          </div>
        </div>
      </div>

      <div class="text-center">
        <p>
            <strong>Vehicle condition</strong>
        </p>
        <span style=""> good </span>
        <input type="radio" id="veh_condition_1" name="vehicle_condition" value=1 v-model="theLot.vehicle_condition"> 1
        <input type="radio" id="veh_condition_2" name="vehicle_condition" value=2 v-model="theLot.vehicle_condition"> 2
        <input type="radio" id="veh_condition_3" name="vehicle_condition" value=3 v-model="theLot.vehicle_condition"> 3
        <input type="radio" id="veh_condition_4" name="vehicle_condition" value=4 v-model="theLot.vehicle_condition"> 4
        <input type="radio" id="veh_condition_5" name="vehicle_condition" value=5 v-model="theLot.vehicle_condition"> 5
        <span> complete wreck </span>
        <p></p>
      </div>

      <div v-if="!editText">
        <label class="form-control-label text-center small w-100 mb-0 ">Mileage</label>
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text small px-1">MI</span>
          </div>
          <input type="text" ref="first-input" class="form-control form-control-sm" placeholder="" @keyup="theLot.kilometers = calculateKm(theLot.mileage)" style="width: 80px;" v-model="theLot.mileage">
          <div class="input-group-prepend">
            <span class="input-group-text small">KM</span>
          </div>
          <input type="text" class="form-control form-control-sm" placeholder="" @keyup="theLot.mileage = calculateMi(theLot.kilometers)" style="width: 80px;" v-model="theLot.kilometers">
        </div>


        <label class="form-control-label text-center small w-100 mb-0 ">Mileage after restoration</label>
        <div class="input-group input-group-sm mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text small">MI</span>
          </div>
          <input type="text" class="form-control form-control-sm" placeholder="" @keyup="theLot.kilometers_after_restauration = calculateKm(theLot.mileage_after_restauration)" style="width: 80px;" v-model="theLot.mileage_after_restauration">
          <div class="input-group-prepend">
            <span class="input-group-text small">KM</span>
          </div>
          <input type="text" class="form-control form-control-sm" placeholder="" @keyup="theLot.mileage_after_restauration = calculateMi(theLot.kilometers_after_restauration)" style="width: 80px;" v-model="theLot.kilometers_after_restauration">
        </div>
      </div>

      <div class="row m-0" v-if="theLot && !editText">
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Registration Number</label>
          <input type="text" class="form-control form-control-sm" placeholder="" v-model="theLot.registration_no">
        </div>
      </div>

      <div class="row m-2" v-if="theLot && !editText">
        <div class="col">
          <div class="form-check">
            <input v-model="theLot.celebrity" class="form-check-input" type="checkbox" value="" :id="`lot-vip-${theLot.id}`">
            <label class="form-check-label" :for="`lot-vip-${theLot.id}`">
              Is celebrity
            </label>
          </div>
        </div>
      </div>

      <div class="row m-2" v-if="theLot && !editText">
        <div class="col">
          <div class="form-check">
            <input v-model="theLot.no_reserve" class="form-check-input" type="checkbox" value="" :id="`lot-no-reserve-${theLot.id}`">
            <label class="form-check-label" :for="`lot-no-reserve-${theLot.id}`">
              No Reserve
            </label>
          </div>
        </div>
      </div>

      <div class="row m-0" v-if="theLot && !editText">
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Year of restoration</label>
          <input type="text" class="form-control form-control-sm" placeholder="" v-model="theLot.restauration_year">
        </div>
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Number of owners</label>
          <input type="number" class="form-control form-control-sm" placeholder="" v-model="theLot.number_of_owners">
        </div>
      </div>

      <div class="row" v-if="theLot && !editText">
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Currency</label>
          <select class="custom-select custom-select-sm" v-model="theLot.currency_id">
            <option :value="currency.id" v-for="currency in currencies" :selected="theLot.currency_id == currency.id">{{ currency.symbol }}</option>
          </select>
        </div>
      </div>

      <div class="row m-0" v-if="theLot && !editText">
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Price</label>
          <input type="number" class="form-control form-control-sm" placeholder="" v-model="theLot.auction_price">
        </div>
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Highest Bid</label>
          <input type="number" class="form-control form-control-sm" placeholder="" v-model="theLot.highest_bid">
        </div>
      </div>

      <div class="row m-0" v-if="theLot && !editText">
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Estiamted Low</label>
          <input type="number" class="form-control form-control-sm" placeholder="" v-model="theLot.estimated_price_low">
        </div>
        <div class="col">
          <label class="form-control-label text-center small w-100 mb-0 ">Estimated High</label>
          <input type="number" class="form-control form-control-sm" placeholder="" v-model="theLot.estimated_price_high">
        </div>
      </div>

      <div class="row m-0" v-if="theLot">
        <div class="col" v-if="!editText">
          <button @click="editText = true" class="btn btn-outline-secondary btn-block btn-sm mt-2"><i class='fas fa-edit'></i> Edit description</button>
        </div>
        <div class="col" v-if="editText">
          <label class="form-control-label text-center small w-100 mb-0 ">Description</label>
          <textarea rows="18" class="form-control form-control-sm" placeholder="Lot description" v-model="theLot.description"></textarea>
          <button @click="editText = false" class="btn btn-outline-secondary btn-block btn-sm mt-1">Hide text field</button>
        </div>
      </div>




      <div class="row mt-2" v-if="lotChanged">
        <div class="col">
          <button @click="save" :disabled="loading" class="btn btn-success btn-block">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
export default {

  name: 'lotSettings',
  props: ['lot', 'currencies'],
  data () {
    return {
      originalLot: null,
      theLot: null,
      editText: false,
      loading: false
    }
  },
  mounted() {
    this.theLot = {...this.lot}
    this.originalLot = {...this.lot}
  },
  computed: {
    lotChanged() {
      return !(JSON.stringify(this.theLot) === JSON.stringify(this.originalLot))
    }
  },
  methods: {
    save() {
      this.loading = true
      axios.put(`/auction_lots/${this.lot.id}.json`, {
        auction_lot: this.theLot,
      }).then((response) => {
        console.log('updated')
        console.log(response.data)
        this.originalLot = {...response.data}
        this.theLot = {...response.data}
        this.loading = false
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    calculateKm(n){
      return Math.round(n*1.609344)
    },
    calculateMi(n){
      return Math.round(n*0.621371192)
    }
  }
}
</script>

<style lang="css" scoped>
</style>