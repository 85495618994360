<template>
  <div>
    <Keypress :key-code="70" event="keyup" @pressed="focusFirstInput" />


    <div v-if="car.auction_lots.length > 1" class="small text-muted text-center">{{lot.auction.short_date}}</div>


    <div class="form-check">
      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" :value="false" v-model="lot.mileage_unknown">
      <label class="form-check-label" for="exampleRadios1" v-if="car.auction_lots.length > 1">Mileage is known</label>
      <label class="form-check-label" for="exampleRadios1" v-if="car.auction_lots.length == 1">Mileage is known</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" :value="true" v-model="lot.mileage_unknown">
      <label class="form-check-label" for="exampleRadios2" v-if="car.auction_lots.length > 1">Mileage is unknown</label>
      <label class="form-check-label" for="exampleRadios2" v-if="car.auction_lots.length == 1">Mileage is unknown</label>
    </div>

    <div class="form-check">
        <p></p>
        <span style=""> good </span>
        <input type="radio" id="veh_condition_1" name="vehicle_condition" value=1 v-model="lot.vehicle_condition"> 1
        <input type="radio" id="veh_condition_2" name="vehicle_condition" value=2 v-model="lot.vehicle_condition"> 2
        <input type="radio" id="veh_condition_3" name="vehicle_condition" value=3 v-model="lot.vehicle_condition"> 3
        <input type="radio" id="veh_condition_4" name="vehicle_condition" value=4 v-model="lot.vehicle_condition"> 4
        <input type="radio" id="veh_condition_5" name="vehicle_condition" value=5 v-model="lot.vehicle_condition"> 5
        <span> wreck </span>
        <p></p>
    </div>

    <div v-if="true || lot.mileage_unknown == false">
      <label class="form-control-label text-center small w-100 mb-0 ">Mileage</label>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text small px-1">MI <kbd class="my-0 ml-2 mr-0 py-0 px-2">F</kbd></span>
        </div>
        <input @keydown.esc="$event.target.blur()" type="text" ref="first-input" class="form-control form-control-sm" placeholder="" @keyup="lot.kilometers = calculateKm(lot.mileage)" style="width: 80px;" v-model="lot.mileage">
        <div class="input-group-prepend">
          <span class="input-group-text small">KM</span>
        </div>
        <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" @keyup="lot.mileage = calculateMi(lot.kilometers)" style="width: 80px;" v-model="lot.kilometers">
      </div>


      <label class="form-control-label text-center small w-100 mb-0 ">Mileage after restoration</label>
      <div class="input-group input-group-sm mb-2">
        <div class="input-group-prepend">
          <span class="input-group-text small">MI</span>
        </div>
        <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" @keyup="lot.kilometers_after_restauration = calculateKm(lot.mileage_after_restauration)" style="width: 80px;" v-model="lot.mileage_after_restauration">
        <div class="input-group-prepend">
          <span class="input-group-text small">KM</span>
        </div>
        <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" @keyup="lot.mileage_after_restauration = calculateMi(lot.kilometers_after_restauration)" style="width: 80px;" v-model="lot.kilometers_after_restauration">
      </div>
    </div>

    <div class="row m-0">
      <div class="col">
        <label class="form-control-label text-center small w-100 mb-0 ">Year of restoration</label>
        <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" v-model="lot.restauration_year">
      </div>
      <div class="col">
        <label class="form-control-label text-center small w-100 mb-0 ">Number of owners</label>
        <input @keydown.esc="$event.target.blur()" type="number" class="form-control form-control-sm" placeholder="" v-model="lot.number_of_owners">
      </div>
    </div>



    <div class="row m-0">
      <div class="col">
        <label class="form-control-label text-center small w-100 mb-0 ">Number of produced</label>
        <input @keydown.esc="$event.target.blur()" type="number" class="form-control form-control-sm" placeholder="" v-model="lot.produced">
      </div>
      <div class="col">
        <label class="form-control-label text-center small w-100 mb-0 ">Registration No.</label>
        <input @keydown.esc="$event.target.blur()" type="text" class="form-control form-control-sm" placeholder="" v-model="lot.registration_no">
      </div>
    </div>


  </div>
</template>

<script>
import Keypress from 'vue-keypress'

export default {

  name: 'MileageInput',
  components: {Keypress},
  props: ['lot', 'car', 'stat'],
  data () {
    return {

    }
  },
  mounted(){
    if (this.lot.mileage && this.lot.mileage > 0){
      this.lot.mileage_unknown = false
    }
    if (this.stat == 'noMileage' && this.$refs){
        console.log("autofocus")
        console.log(this.$refs)

        this.focusFirstInput()
    }
  },
  methods: {
    setUnknown() {
      console.log('setting mileage')
      console.log(this.lot.mileage)
      if (this.lot.mileage && this.lot.mileage > 0){
        this.lot.mileage_unknown = false
      } else {
        this.lot.mileage_unknown = true
      }
    },
    blurInputs(event){
      // console.log(event)
      // console.log(this.$event)
      event.target.blur()
    },
    focusFirstInput(){

      this.$refs["first-input"].focus()
    },
    calculateKm(n){
      this.setUnknown()
      return Math.round(n*1.609344)
    },
    calculateMi(n){
      this.setUnknown()
      return Math.round(n*0.621371192)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
