<template>
  <div id="command-center" class="bg-light">

    <CategorizeOptionSelector @update="updateFilter" :makeId="makeId" :makes="makes" class="bg-light" id="option-selector"></CategorizeOptionSelector>

    <div id="main-screen">

      <div v-if="!makeId">
        <div class="row mx-0">
          <div class="col-10 mx-auto text-center bg-white shadow rounded mt-4 p-4">
            Choose a brand and then look at all the cars of that brand to fill in the missing data.
          </div>
        </div>
      </div>

      <div class="row" v-if="loading">
        <div class="col text-center py-4">
          <i class="fas fa-spinner fa-spin fa-3x"></i>
        </div>
      </div>

      <div class="bg-white p-3 rounded" v-if="cars.length">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Year</th>
              <th>Model</th>
              <th>Current</th>
              <th>Removed</th>
              <th>Suggestion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="car in cars">
              <td>
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" v-model="selectedCars" :value="car.id">
                </div>
              </td>
              <td>{{ car.year }}</td>
              <td @click="addOrRemove(car.id)">{{ car.name }}</td>
              <td>
                <div v-if="car.categories.length > 1">
                  <span class="badge badge-primary mr-1" v-for="cat in car.categories">
                    {{ cat.name }}
                  </span>
                </div>
              </td>
              <td>
                <div v-if="car.deleted_categories.length > 1">
                  <span class="badge badge-primary mr-1" v-for="cat in car.deleted_categories">
                    {{ cat.name }}
                  </span>
                </div>
              </td>
              <td>
                <div v-if="car.suggestions.length > 1">
                  <span class="badge badge-primary mr-1" v-for="sug in car.suggestions">
                    {{ sug.full_name }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="commands" class="bg-light p-2" v-if="cars.length">
      <div class="row">
        <div class="col">
          <treeselect
            v-model="selectedCategory"
            :autoFocus="false"
            :multiple="false"
            :options="subCategories"
            :disableBranchNodes="true"
          />
        </div>
        <div class="col">
          {{ selectedCars }}
        </div>
      </div>

    </div>


  </div>
</template>

<script>

import Keypress from 'vue-keypress'
import CategorizeOptionSelector from './categorizeOptionSelector.vue'
import CarDetails from './carDetails.vue'
  // import the component
import Treeselect from '@riophae/vue-treeselect'
  // import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import axios from 'axios'

export default {

  name: 'test',
  components: {Treeselect, CategorizeOptionSelector, CarDetails, Keypress},
  props: ['development', 'makes'],
  data () {
    return {
      loading: false,
      cars: [],
      workedOff: [],
      selectedCars: [],
      showSaved: false,
      showList: false,
      total: 0,
      selectedCategory: null,
      subCategories: [],
      order: null,
      makeId: null,
      currentIndex: 0,
      selectedCar: null,
    }
  },
  created(){
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    this.parseFromUrl()
  },
  computed: {
    commandGridRowStyle(){
      let h = 58
      if (this.selectedCar && this.selectedCar.auction_lots && this.selectedCar.auction_lots.length > 1){
        h = this.selectedCar.auction_lots.length * 48
      } else {
        // const h = 58
      }
      return `grid-template-rows: 56px 1fr ${h}px;`
    }

  },
  methods: {
    addOrRemove(value) {
      var index = this.selectedCars.indexOf(value)
      if (index === -1) {
        this.selectedCars.push(value)
      } else {
        this.selectedCars.splice(index, 1)
      }
    },
    parseFromUrl(){
      // console.log(this.$router)
      // ["", "command-center", "noTransmission", "329812", "223663"]
      const attrs = window.location.pathname.split("/")
      if (attrs.length > 1){
        // this.stat   = attrs[2]
        this.makeId = attrs[2]
        if (this.makeId){
          this.updateFilter({order: this.order, stat: this.stat, make_id: this.makeId, carId: attrs[3]})
        }
      }

    },
    canSave(){
      // return (this.markedTAutomatic === true || this.markedTUnknown === true || this.markedTManual === true )
      return true
    },

    markCar(type){
      if (this.selectedCar){
        this.selectedCar.transmission_type = type
      }
    },
    saveCar(){
      var car = this.selectedCar
      return axios.put(`/cars/${this.selectedCar.id}/command-center.json`, {car}).
      then(response => {
        // console.log(response.data)
        this.workedOff.push(car)
      }).catch(error => {
        alert("Something went wrong on the server. Submittion failed. A report has been sent to the administrators.")
      });

    },
    saveCarAndRemoveFromList() {
      if (this.selectedCar && this.canSave()){
        this.saveCar()
        this.markedTAutomatic = false
        this.markedTUnknown   = false
        this.markedTManual    = false
        this.cars.splice(this.currentIndex, 1)
        if (this.cars[this.currentIndex]){
          this.loadCar(this.cars[this.currentIndex])
        } else if (this.cars.length == 1 && this.cars[0].id == this.selectedCar.id){
          this.selectedCar = null
          history.pushState({}, "Done", `/categorize`)
        } else if (this.cars.length) {
          this.loadCar(this.cars[this.cars.length - 1])
        } else {
          this.selectedCar = null
          history.pushState({}, "Done", `/categorize`)
        }
      }


      // arr.splice(2,2);
    },
    canSelectNext(){
      if (this.currentIndex < (this.cars.length - 1)){
        return true
      } else {
        return false
      }
    },
    loadCar(car){


      // this.loading = true
      this.showSaved = false
      this.showList = false
      this.selectedCar = car
      return axios.get(`/cars/${car.id}.json`).
        then(response => {
          this.loading = false
          // only load if reponse.data.id == selectedCar.id
          if (this.selectedCar.id == response.data.id) {
            history.pushState({selectedCar: this.selectedCar, currentIndex: this.currentIndex}, car.full_name, `/categorize/${this.makeId}/${response.data.id}`)
            this.selectedCar = response.data
          }
        })
    },
    selectPrev(){
      if (this.currentIndex > 0){
        this.loading = true
        this.selectedCar = null
        this.currentIndex --
        this.loadCar(this.cars[this.currentIndex])
      }
    },
    selectNext(){
      if (this.canSelectNext()){
        this.loading = true
        this.selectedCar = null
        this.currentIndex ++
        this.loadCar(this.cars[this.currentIndex])
      }
    },
    loadModelData() {
      axios({
        method: 'get',
        url: `/categories/${this.makeId}/category_tree.json`,
      }).then((response) => {
        console.log('data')
        console.log(response)
        this.subCategories = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    updateFilter(obj){
      this.loading      = true
      this.total        = 0
      this.cars         = []
      this.selectedCars = []
      this.workedOff    = []
      this.showSaved    = false
      this.selectedCar  = null
      this.makeId       = obj.make_id
      // this.stat         = obj.stat
      this.loadModelData()
      return axios.get(`/categorize-filter.json?make_id=${obj.make_id}`).
      then(response => {
        this.cars         = response.data.cars
        this.total        = response.data.total
        this.loading      = false
        this.currentIndex = 0
        // if (this.cars.length){
        //   if (obj.carId){
        //     for (var i = this.cars.length - 1; i >= 0; i--) {
        //       if (this.cars[i].id == obj.carId){
        //         this.currentIndex = i
        //         this.loadCar(this.cars[i])
        //       }
        //     }
        //   } else {
        //     this.loadCar(this.cars[this.currentIndex])
        //   }
        // }
      })
    }
  }
}
</script>

<style lang="css" scoped>
  #option-selector{
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 2;

  }
  #main-screen{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    overflow: auto;
  }
  #command-center{
    background: #fefefe;
    height: calc(100vh);
    display: grid;
    grid-template-rows: 56px 1fr 56px;
    grid-template-columns: 1fr 500px;
  }
  #commands {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    background: #333;
    border-top: 3px solid #888;
  }

</style>
